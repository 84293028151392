html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font: 112.5%/1.45em georgia, serif, sans-serif;
  box-sizing: border-box;
  overflow-y: scroll;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: hsla(0, 0%, 0%, 0.8);
  font-family: georgia, serif;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

main{
  margin:0 auto;
}

.link-social{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color:white;
}
.head-link{
  position:absolute;
  left:40%;
  right:0;
}
.head-link a{
  color:white;
  text-decoration:none;
}

.bg{
  background-color: rgba(0, 0, 0, 0.3);
}
.bg-red{
  background-color:black;
  width:100%;
  margin-top: -50px;
}
.bg-red h3 p{
  color:white;
}
.rot{
  margin-top:-10px
}
.rotation90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.store-img{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position:relative;
  top:-70%;
  left:60px;
  right: 0;
}
.st{
  display: block;
  width: 160px;
  height:auto;
}
.st:hover{
  cursor: pointer;
}
.img-head{
  width:100%;
  height: 400px;
}
.text-header{
  display:block;
  position: absolute;
  top:25%;
  left:50%;
  right: 0;
  transform: translate(-50%, -50%);
  color:white;
  font-size: 1em;
}
.col-welcome{
  display: flex;
  flex-direction:row;
  justify-content:space-evenly;
  align-items: center;
  align-self: baseline;
  padding:50px;
  margin-top:20px;

}
.first-w{
  display: flex;
  flex-direction: column;
  width:550px;
  padding: 10px;
  margin-left: 10px;
  margin-top:-10px;
}
.welcome-card{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: 30px;
  width:500px;
  padding: 15px;
}
.text-welcome{
  display:block;
  margin-top: 15px;
  font-size: 1em;
  font-weight:500;
  color:black;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
}

.w-card{
  display:flex;
  flex-direction: column;
  border-radius: 10px 10px 10px 10px;
  background-color: white;
  width:220px;
  height:auto;
  border: 1px solid black;
  padding: 5px;
  margin-bottom: 15px;

}
.w-card h6{
  text-align: center;
}
.w-card:hover{
  transform: scaleX(1.1) scaleY(1.1);
  cursor:pointer;
}
.w-img{
  display:block;
  width:220px;
  height: 120px;
}
.w-card h6{
  font-weight: bold;
}
.w-text{
  font-size: 0.8rem;

}

.line-one{
  border-bottom: 1px solid red;
  width:60px;
  position:relative;
  left:42%;
  margin-bottom: 5px;
}
.line-two{
  border-bottom: 2px solid red;
  width:120px;
  position:relative;
  left:37%;
  right: 0;
}
.bg-r{
  background-color:#FF123C;
  height:auto;
  position:relative;
  left:14%;
  right:0;
}
.row-service{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:10px;
}
.service-card{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-service{
  display: block;
  border-radius:30%;
  width:80px;
  height:auto;
}
.service-card span{
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  margin-top: 7px;
}
.service{
  background-color: #dd072e;
  /*background: no-repeat url("../images/berto.png") fixed;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 30px;
}
.service .hs{
  color: white;
  font-style: normal;
  font-weight: 500;
}
.line-service-one{
  border-bottom: 1px solid rgb(255, 255, 255);
  width:90px;
  position:relative;
  left:41%;
  right:0;
  margin-bottom: 5px; 
}
.home-icon{
  display: block;
  width:300px;
  height: auto;
  margin: auto;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
  left:10%;
  right: 0;
}
 .f-icon{
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  align-self: center;
  align-content: center;
  margin-top: 20px;
  flex-flow: row;
  width:250px;
}
.f-icon .fa{
  margin-top:-5px;
  margin-right:10px;
}
.f-icon .number{
  position: relative;
  left:5%;
  right:0;
}
.f-icon .av{
  margin-top: 15px;
  margin-left: 2px;
}
.f-icon a{
  text-decoration: none;
  color: white;
  position: relative;
  left:5%;
  right:0;
 
}
.f-icon a:hover{
  color: white;
}
.menu ul>li>a{
  text-decoration: none;
  margin-top: 10px;
}
.link .social{
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.link-social ul,li{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left:20px
}
/*  component buy */

.buy-text{
  position: relative;
  left: 0;
  top:0;
  right: 0;
  color: black;
}

.b-h{
  background: url('../images/buy.jpg') no-repeat;
  background-size: cover;
  display:block;
  width:auto;
}

/** component sell*/

.sell-form{
  background: no-repeat url("../images/sell.png") ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 30px;
  padding-bottom: 50px;
}
.formulaire{
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  left:20%;
  right:0;
  width:900px;
  padding-top:10px;
  padding-right: 10px;
  height: auto;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  color:white;
}
.head-form{
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  margin-left:10px;
}
 .nb{
  display:flex;
  flex-direction:row;
  align-items: center;
}

.nb span{
  border: 1px solid red;
  padding:10px;
  padding-left:15px;
  width:50px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 80% 70% / 80% 80%;
  font-size:1.6rem;
  margin-right: 20px;
}
.form{
  background-color:white;
  width:inherit !important;
  height:auto !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding-bottom: 50px;
}
.form-sell-group{
  display: inline-flex;
  flex-direction: row;
  justify-content:space-evenly;
  align-content: flex-start;
  margin-left: 50px
}
.sell-control{
  margin-left:10px;
}
.sl-20{
  margin-left: 5%;
  margin-top: 15px;
}
.btn-c{
  position: absolute;
  right: 5%;
  right:0;
  bottom: 10px;
  padding-right: 5px;
}
.w-sell{
  margin-left: 12px;
}
.content-page ul li{
  list-style: disc;
}
.content-page li::marker{
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}

@media only screen and (max-width:1024px){

}

@media only screen and (max-width: 990px) {
  .head-link{
    display:block;
    position: relative;
    left: 0;
    right: 0;
  }
  .logo{
    width:100px;
    display:block;
    height:auto;
    margin-left:20px;
  }
  w{
    width:100%;
    height:auto;
  }
  .img-head{
    width:100%;
    height:auto;
  }
  .text-header{
    display:block;
    position: absolute;
    top:40%;
    left:50%;
    right:0;
    transform: translate(-50%, -50%);
    color:white;
    font-size: 1em;
  }
  .text-welcome{
    display:block;
    margin-top: 0px;
    font-size: 0.8em;
    font-weight:600;
    color:rgb(14, 13, 13);
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
  }
  .col-welcome{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .formulaire{
    position: relative;
    left:12%;
    right:0;
    width:650px;
    display:block;
  }
  .store-img{
    display: flex;
    flex-direction: column;
  }
}



@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
  body{
    padding:0px !important;
    margin:0px !important;
  }
  .sl{
    margin-left: 25px !important;
  }
  .service{
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
  }
  .col-welcome{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .first-w{
    display: flex;
    width:inherit;
  }
  .welcome-card{
    display: flex;
    width: inherit;
    justify-content: space-evenly;
  
  }
  
  .bg-red{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
  }
  .w-xs{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
  }
  
.w-card{
  display:flex;
  flex-direction: column;
  border-radius: 10px 10px 10px 10px;
  background-color: white;
  width:150px;
  height:auto;
  border: 1px solid black;
  padding: 5px;
  margin-bottom: 15px;

}
.w-card h6{
  text-align: center;
}
.w-card:hover{
  transform: scaleX(1.1) scaleY(1.1);
  cursor:pointer;
}
.w-img{
  display:block;
  width:120px;
  height: 50px;
}
.w-card h6{
  font-weight: bold;
}
.w-text{
  font-size: 0.8rem;

}

.menu{
  display:block;
  width: 300px;
  margin: auto;
  height: auto;
  position: relative;
  left:30%;
  right: 0;
}
.store-img{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position:relative;
  top:-60%;
  left:20%;
  right:0;
}
.store-img:first-child{
  margin-left: 20px;
}
.rot{
  margin-top:20px;
}
.st{
  margin-bottom:20px
}
.line-service-one{
  left:38%;
  width: 70px;
}
.sell-form{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formulaire{
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  left:1.3%;
  right:0;
  width:380px;
  padding-top:10px;
  height: auto;
  border: 1px solid gray;
  display: block;
  flex-direction: column;
  color:white;
}
.head-form{
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  margin-left:10px;
}
 .nb{
  display:flex;
  flex-direction:row;
  align-items: center;
}
.w-sell{
  width:130%;
  margin-left: 12px;
}
.nb span{
  border: 1px solid red;
  padding:10px;
  padding-left:15px;
  width:50px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 80% 70% / 80% 80%;
  font-size:1.6rem;
  margin-right: 20px;
}
.head-form .detail{
  font-size: 1rem;

}
.form{
  background-color:white;
  width:inherit !important;
  height:auto !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding-bottom: 50px;
  padding: 10px;
}
.form-sell-group{
  display: inline-flex;
  flex-direction: row;
  justify-content:space-evenly;
  align-content: flex-start;
  margin-left: 10px
}
.btn-c{
  position: relative;
  right: 5%;
  margin-top:10%;
  left: 20%;
  right:0;
}
}
