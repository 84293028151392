section#page-banner {
  background: no-repeat  cover center center;
  padding-top: 200px;
  padding-bottom: 60px;

  .pt-200 {
    padding-top: 200px;
  }
  .pb-35 {
    padding-bottom: 35px;
  }
  .bg_cover {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  a{
    text-decoration: none;
    color: #0869b0;
  }
  .banner-content h3 {
    position: relative;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    padding-left: 25px;
  }
  .banner-content h3::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background-color: #ffffff;
    top: 0;
    left: 0;
  }
  ol .breadcrumb {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    padding: 0px 0px 0px 25px !important;
    margin-bottom: 1rem;
    list-style: none;
    background-color: transparent;
    border-radius: 0.25rem;
    li {
      a {
        font-family: sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        font-size: 16px;
        color: #0869b0;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

section#contact-content {
  margin-top: 15px;
  padding-top: 20px;
  padding-bottom: 15px;
  .cammy-info {
    h1 {
      font-size: 24px;
      font-weight: 700;
      font-family: sans-serif;
      font-style: normal;
      color: rgba(36, 31, 31, 0.8);
    }
    p {
      margin-top: 16px;
      font-size: 15px;
      text-align: justify;
      font-family: "Montserrat" sans-serif;
      a {
        text-decoration: none;
        color: inherit;
        margin-left: 35px;
        margin-top: 10px;
      }
      &:hover {
        text-decoration: none;
        color: none;
      }
    }
  }
}

section#mp {
  margin-top: 10px;
  padding-top: 50px;
}

.card-leader{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width:700px;
  height: 370px;
  background-color: #2525251c;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 4px 4px rgba(48, 48, 48, 0.3);
  margin-bottom: 20px;
  opacity: 0.8;
  padding: 10px;
  padding-bottom: 0;
  cursor: pointer;
   .social-ceo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    .ceo{
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .l-img{
        position: relative;
        left: -18%;
        top:-5%;
        right: 0;
        width: 150px;
        height: 120px;
        border-radius: 60%;
      }
      .t-ceo{
        display: flex;
        flex-direction: column;
        .ic-ceo{
          margin-top: 4px;
          span{
            color: rgb(57, 57, 245);
            font-style: italic;
            margin-right: 5px;
          }
          a{
            margin-left:10px;
            .fa{
              color:white;
              transition: color .2s ease-in-out;
              &:hover{
                color:#0869b0;
                transition: color .2s ease-in;
              }
            }
          }
        }
      }
    }
    .p-ceo{
      margin-top: 2px;
      p{
        color: #3f3b3b;
        font-size: large;
        font-style: italic;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: start;
      }
    }
    
  }
  @media only screen and (max-width: 480px){
   padding: 0;
   padding-bottom: 0;
   width: 100%;
   height: 460px;
   .social-ceo{
     padding-bottom: 2px;
     margin: 0;
     .ceo{
       .l-img{
         position: relative;
         top:3%;
         left:-3%;
         width: 120px;
         border-radius: 65%;
       }
     }
     .p-ceo{
       margin-top: 20px;
     }
   }
}
}